/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * aktiv-grotesk:
 *   - http://typekit.com/eulas/00000000000000003b9b4066
 *   - http://typekit.com/eulas/00000000000000003b9b4067
 *   - http://typekit.com/eulas/00000000000000003b9b4064
 *   - http://typekit.com/eulas/00000000000000003b9b4069
 *   - http://typekit.com/eulas/00000000000000003b9b4068
 *
 * © 2009-2021 Adobe Systems Incorporated. All Rights Reserved.
 */

/* {"last_published":"2020-05-06 15:37:03 UTC"} */

@import url('https://p.typekit.net/p.css?s=1&k=zua2sve&ht=tk&f=14032.14033.14036.14038.14039&a=9406436&app=typekit&e=css');

@font-face {
  font-family: 'aktiv-grotesk';
  src: url('https://use.typekit.net/af/28158e/00000000000000003b9b4066/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/28158e/00000000000000003b9b4066/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/28158e/00000000000000003b9b4066/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'aktiv-grotesk';
  src: url('https://use.typekit.net/af/386e17/00000000000000003b9b4067/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/386e17/00000000000000003b9b4067/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/386e17/00000000000000003b9b4067/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'aktiv-grotesk';
  src: url('https://use.typekit.net/af/76d40d/00000000000000003b9b4064/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/76d40d/00000000000000003b9b4064/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
      format('woff'),
    url('https://use.typekit.net/af/76d40d/00000000000000003b9b4064/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'aktiv-grotesk';
  src: url('https://use.typekit.net/af/e604a2/00000000000000003b9b4069/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/e604a2/00000000000000003b9b4069/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
      format('woff'),
    url('https://use.typekit.net/af/e604a2/00000000000000003b9b4069/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'aktiv-grotesk';
  src: url('https://use.typekit.net/af/115b23/00000000000000003b9b4068/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/115b23/00000000000000003b9b4068/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3')
      format('woff'),
    url('https://use.typekit.net/af/115b23/00000000000000003b9b4068/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3')
      format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 500;
}

.tk-aktiv-grotesk {
  font-family: 'aktiv-grotesk', sans-serif;
}
