@import 'shared';

.Preloader {
  @include position-100(fixed);
  @include z-index(preloader);

  .loadContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .preloadSvg {
    transform: rotate(90deg);
    display: initial;
  }

  .circleLine {
    stroke: $secondary-peach;
  }

  .logoContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .logo {
    width: px(110);

    path {
      fill: $secondary-peach;
    }
  }

  .soundDisclaimer {
    position: absolute;
    bottom: px(40);
    left: 0;
    right: 0;

    svg {
      width: px(14);
      margin-bottom: px(5);
    }

    path {
      fill: $secondary-peach;
    }
  }

  .soundDisclaimerText {
    color: $secondary-peach;
    @include font-style(global, p3);
  }

  .rings {
    * {
      top: -125%;
      left: -125%;
      width: 350%;
      height: 350%;
    }
  }
}
