@import 'shared';

.hideCursor {
  cursor: none;

  * {
    cursor: none;
  }

  :global(.touch-device) & {
    cursor: auto;
    * {
      cursor: auto;
    }
  }
}

.Cursor {
  position: fixed;
  opacity: 0;
  top: 0;
  left: 0;
  pointer-events: none;
  @include z-index(cursorElement);

  .inner {
    position: relative;
  }

  .staticCircle {
    fill: $experience-cursor;

    &.media {
      fill: $media-cursor;
    }
  }

  .circleSvg {
    transform: rotate(90deg);
    display: initial;
  }

  .circleLine {
    stroke: $experience-cursor;
  }

  .mediaCircleLine {
    stroke: $media-cursor;
  }

  .caretContainer {
    position: absolute;
    top: calc(50% - #{px(6)});
  }

  .caretContainerRight {
    left: 75%;
  }

  .caretContainerLeft {
    right: 75%;
  }

  .caret {
    position: absolute;
    width: px(5);
    display: block;

    path {
      fill: $experience-cursor;
    }
  }

  .caretLeft {
    right: calc(100% + #{px(13)});
  }

  .caretRight {
    left: calc(100% + #{px(12)});
    transform: rotate(-180deg);
  }

  .circleFallback {
    stroke: $secondary-peach;

    &.mediaKit {
      stroke: $media-cursor;
    }
  }

  .holdCircle {
    transform: rotate(90deg);
    transform-origin: center;
  }

  .text {
    position: absolute;
    top: 38%;
    left: 0;
    right: 0;
    color: $experience-cursor;
    display: inline-block;
    @include font-style(global, p4);
    white-space: nowrap;

    sup {
      transform: translateY(#{px(2)});
      display: inline-block;
    }

    &.text360 {
      transform: scale(0.85);
    }

    &.textHold {
      top: 78.5%;

      span {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 150%; //To have enough room for longer text
        text-align: center;
        display: block;
      }
    }
  }
}
