@import 'shared';

.PasswordGate {
  @include min-height;
  color: $secondary-peach;
  text-align: center;
  @include flex-center;
  flex-direction: column;
  position: relative;
  overflow: hidden;

  .focusContent {
    &:focus {
      outline: none;
    }
  }

  .subtitle {
    @include font-style(global, p3);
    margin-bottom: px(10);
    text-transform: uppercase;
    position: relative;
    overflow: hidden;
  }

  .title {
    position: relative;
    overflow: hidden;
    margin-top: -1.5vw;
    font-size: 16vw;
    line-height: 1;
    font-weight: 400;
    text-transform: none;

    @include breakpoint(desktopMd) {
      font-size: px(300);
      margin-top: px(-30);
    }
  }

  .headerContainer {
    position: relative;
    margin-top: px(-140);
  }

  .enterExperienceContainer,
  .passwordContainer {
    @include flex-center;
    flex-direction: column;
    position: absolute;
    top: 100%;
    width: 100%;
    padding-top: px(40);
  }

  .passwordContainerInner {
    position: relative;
  }

  .error {
    @include font-style(global, err);
    position: absolute;
    left: 0;
    right: 0;
    bottom: 100%;
  }

  .passwordInput {
    border: none;
    appearance: none;
    background-color: transparent;
    border-bottom: px(1) solid $secondary-peach;
    color: $secondary-peach;
    text-align: center;
    width: px(230);
    height: px(30);
    font-size: px(30);

    &:focus {
      outline: none;
    }
  }

  .passwordInputLabel {
    @include font-style(global, p3);
    display: block;
    margin-top: px(5);
  }

  .buttonContainer {
    @include flex-center;
    margin-top: px(60);
  }

  .button {
    width: px(205);
  }

  .skipToKit {
    :global(.touch-device) & {
      cursor: pointer;
    }

    position: fixed;
    bottom: px(40);
    @include transform-center-horiz;
    text-decoration: underline;
    z-index: 100;
    display: inline-block;

    > button {
      color: inherit;
      background: transparent;
      border: none;
    }
  }
}
