@import 'layout.scss';

// Variables
//
// Add global variables here, color palette, those variables shared by components all applied to global styling

// ====================================================================
// Breakpoints
//
// Window breakpoints
// ====================================================================
$layout-mobile: map-get($layout, mobile) + px;
$layout-tablet: map-get($layout, tablet) + px;
$layout-desktopSm: map-get($layout, desktopSm) + px;
$layout-desktopMd: map-get($layout, desktopMd) + px;
$layout-desktopLg: map-get($layout, desktopLg) + px;

// ====================================================================
// Base palette
//
// Grayscale and brand colors for use across the site.
// ====================================================================
$core-white: #fff;
$core-grey: #d5d6d6;
$core-black: #000;

$secondary-red: #65303d;
$secondary-orange: #fb7401;
$secondary-peach: #febca5;

$experience-cursor: #dea490;
$media-cursor: #723645;

$secondary-mahogany: #552833;

$purple: #d6c8ff;
$purple-over: #b49ef2;
$purple-faded: #68709c;
$dark-purple: #a76fff;
$dark-blue: #1f355a;
$dark-blue-over: #162845;
$red-error: #db3737;
$grey-medium-4: #e3e3e3;
$grey-medium-3: #e4e4e4;
$grey-medium-2: #909090;
$grey-medium: #919191;
$grey-medium-alt: #7a7a7a;
$dark-grey: #232323;
$light-grey: #f4f4f4;

$grey: #c4c4c4;
$grey-2: #f0eff1;
$grey-3: #333;
$grey-4: #f0f0f0;
$grey-5: #efefef;

// ====================================================================
// General
//
// Global color variables, used usually in sections
// Global variables used across the web app
// ====================================================================
$page-bg-color: $core-white;
$element-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.04);
$generic-fonts: aktiv-grotesk, WorkSansMedium, Helvetica, Arial, sans-serif;
$page-gutter: px(60);

// ====================================================================
// Nav bar
// ====================================================================
$navbar-height-mobile: 6rem;
$navbar-height-desktop: 5rem;

// ====================================================================
// List of z-index element
// ====================================================================
$elements: pages, main-nav, explodeClose, sticky-nav, full-width-image, passwordGate, page-logo, soundButton,
  loadingPulse, preloader, cookiesModal, wipe, rotate, cursorElement;

// ====================================================================
// Press and product pages
// ====================================================================
$grid-max-width: px(1800);
$page-padding-left: px(300);
$page-gutter: px(60);
$grid-columns: 12;
$grid-gutter: px(20);

// ====================================================================
// Press Twitter Carousel
// ====================================================================
$overlap-amount: px(57);

// ====================================================================
// Experience nav
// ====================================================================

$experience-nav-bottom: px(50);
