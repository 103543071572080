@import '../../styles/shared.scss';

.WipeElement {
  width: 100%;
  height: 100%;
  position: fixed;
  @include z-index(wipe);
  top: 0;
  left: 0;
  background-color: $secondary-peach;
  transform: translateX(-100.2%);
}
