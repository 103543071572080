@import 'shared';

.SoundButton {
  position: fixed;
  top: 0;
  right: 0;
  padding: px(50) $page-gutter 0;
  display: flex;
  justify-content: flex-end;
  @include z-index(soundButton);

  .button {
    :global(.touch-device) & {
      cursor: pointer;
    }

    background: none;
    outline: none;
    border: none;
    color: $secondary-peach;
    display: flex;
    align-items: center;
    opacity: 0;
  }

  .buttonText {
    margin-right: px(12);
    @include font-style(global, p3);
  }

  .icon {
    position: relative;
    top: px(1);
  }

  .iconImage {
    width: px(18);
    height: px(16);
  }
}
