@import 'shared';

.MediaKitButton {
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  padding: px(50) $page-gutter 0;
  display: flex;
  justify-content: flex-start;
  @include z-index(soundButton);

  .button {
    :global(.touch-device) & {
      cursor: pointer;
    }

    background: none;
    outline: none;
    border: none;
    color: $secondary-peach;
    @include font-style(global, p3);
  }
}
