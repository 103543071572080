@import '../../styles/shared.scss';

$pulse-duration: 2.7s;
$pulse-size: px(150);

.LoadingPulse {
  width: $pulse-size;
  height: $pulse-size;
  filter: blur(0.5px); // It's a little too "crisp" for my liking without this
  pointer-events: none;

  .ring1,
  .ring2 {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: $pulse-size;
    border: 1px solid $secondary-peach;
    animation-timing-function: cubic-bezier(0.135, 0.995, 0.2, 1); /* custom */
  }

  .ring1 {
    :global {
      animation: loadingPulse $pulse-duration linear infinite;
    }
  }

  .ring2 {
    :global {
      animation: loadingPulse $pulse-duration #{div($pulse-duration, 2)} linear infinite;
    }
  }
}
