@import 'shared';

.loadingPulse {
  position: fixed;
  @include z-index(loadingPulse);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.1;
}

.PageGateWrapper {
  .passwordGate {
    @include position-100(fixed);
    @include z-index(passwordGate);
  }
}
