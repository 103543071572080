@import '../../styles/shared.scss';

.EnterPillButton {
  @include reset-button;
  border: px(1) solid var(--text-color);
  padding: px(13) px(29);
  border-radius: px(27);
  color: var(--text-color);
  @include flex-center-vert;
  justify-content: center;
  max-height: px(40); //prevent svg from increasing height of the button
  transition: opacity 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
  position: relative;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  mask-image: -webkit-radial-gradient(white, black);

  .text,
  .secondaryText {
    @include font-size(17, 21);
  }

  .text {
    opacity: 1;
    visibility: visible;
  }

  .circle {
    background-color: $secondary-peach;
    border-radius: 50%;
    width: 250px;
    height: 250px;
    position: absolute;
    transform-origin: center center;
    transform: scale(0);
  }

  .wrapper {
    position: absolute;
    overflow: hidden;
  }

  .secondaryText {
    opacity: 0;
    visibility: hidden;
    color: $secondary-red;
    transform: translateY(10px);
  }
}
