@keyframes loadingPulse {
  from {
    transform: scale(0.005);
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  40% {
    opacity: 1;
    animation-timing-function: ease-out; /* custom */
  }
  60% {
    animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); /* custom */
    transform: scale(0.8);
    opacity: 1;
  }
  to {
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes hotspotPulse {
  from {
    transform: scale(1);
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  40% {
    opacity: 1;
    animation-timing-function: ease-out; /* custom */
  }
  60% {
    animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); /* custom */
    transform: scale(1.8);
    opacity: 1;
  }
  to {
    transform: scale(2);
    opacity: 0;
  }
}
