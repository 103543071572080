@import 'shared';

.SonosLogo {
  padding: px(50) $page-gutter 0 $page-gutter;
  line-height: px(50);
  left: 50%;
  transform: translate(-50%, 0);
  position: absolute;
  text-align: left;
  @include z-index(page-logo);
  @include theme;

  .hoverArea {
    width: fit-content;
    display: inline-block;
  }

  .button {
    background: none;
    outline: none;
    border: none;
    color: $secondary-peach;
    width: px(111);
    height: px(23);
    transform: scale(1.1);
    opacity: 0;
    display: flex;
  }

  &.center {
    justify-content: center;
  }

  svg {
    width: 100%;
    height: 100%;
  }

  &.light {
    path {
      fill: $secondary-peach;
    }
  }

  &.dark {
    path {
      fill: $core-black;
    }
  }

  span.screenReaderOnly {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
}
