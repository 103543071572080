@import '../../styles/shared.scss';

.PillButton {
  mask-image: radial-gradient(white, black);
  @include reset-button;
  border: px(1) solid var(--text-color);
  padding: px(13) px(29);
  border-radius: px(27);
  color: var(--text-color);
  @include flex-center-vert;
  justify-content: center;
  max-height: px(40); //prevent svg from increasing height of the button
  transition: all 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
  position: relative;
  overflow: hidden;

  .text {
    @include font-size(17, 21);
  }

  &.withIcon {
    padding: px(13);
    max-height: px(50);
  }

  svg {
    margin-right: px(10);
    width: px(28);
  }

  path {
    fill: $secondary-red;
  }

  circle {
    fill: var(--text-color);
  }

  @media (hover: hover) {
    &:hover {
      color: $secondary-red;
      background-color: $secondary-peach;

      circle {
        fill: $secondary-red;
      }

      path {
        fill: var(--text-color);
      }
    }

    &:hover::before {
      left: 0%;
      width: 100%;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 0;
      z-index: -1;
      transition: all 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
      color: $secondary-red;
      background-color: $secondary-peach;
    }
  }
}
