@import 'shared';

.Rings {
  .ring {
    position: absolute;
    border-radius: 50%;
    border: 1px solid $secondary-peach;
    opacity: 0;

    // Set the info below in a per-component basis where necessary
    // top: -125%;
    // left: -125%;
    // width: 350%;
    // height: 350%;
  }
}
