@import '../../styles/shared.scss';

.CookiesModal {
  width: px(433);
  border-radius: 8px;
  color: $core-black;
  background-color: $core-white;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  padding: px(32) px(32) px(36);
  @include z-index(cookiesModal);

  &.hide {
    display: none;
  }

  .hoverArea {
    display: inline-block;
  }

  .title {
    @include font-size(23, 28.52, normal, 700);
    margin-bottom: px(25);
  }

  .description {
    @include font-size(16, 20.64);
    margin-bottom: px(37);

    a {
      text-decoration: underline;
    }
  }

  .buttonHoverArea {
    margin-bottom: px(37);
    display: inline-block;

    .button {
      background-color: $core-black;
      border-radius: px(100);
      color: $core-white;
      height: px(39);
      @include font-size(16, 20.64);
      @include flex-center;
      display: inline-flex;
      border: none;
      width: px(139);
    }
  }

  .settingsHoverArea {
    width: px(98);
    margin: 0 auto;

    .settings {
      @include font-size(12, 15.48);
      text-decoration: underline;
      display: block;
    }
  }
}
